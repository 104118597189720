import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Card, CardBody, CardTitle, Label } from "reactstrap";
import Header from "../../components/Header/Header";
import { IGetCountryListParams, getNotificationsList } from "../../redux/actions/notificationsAction";
import { ISingleNotification } from "../../redux/types/notificationsTypes";
import { formatDateToCustomDateString, formatDateToLocaleDateString } from "../../helpers/helperFunctions";
import { useSelector } from "react-redux";
import { ERROR_MESSAGES } from "../../helpers/constants";
import { ErrorToast } from "../../utils/toastNotifications";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";

const Activities = () => {
  const [notifications, setNotifications] = useState([]);
  const { user_info } = useSelector((state: any) => state.user);
  const [filters, set_filters] = useState({ start_date: "", end_date: "", sort_order: " " });
  const [filterOpen, setfilterOpen] = useState<boolean>(false);
  const [header_state, setheader_state]: any = useState(
    Boolean(localStorage.getItem("header"))
  );


  const handleFilterApply = async () => {
    const { start_date, end_date, sort_order } = filters;

    if (start_date.trim() === "" && end_date.trim() === "" && sort_order.trim() === "") {
      ErrorToast(ERROR_MESSAGES.noFiltersSelected);
      return;
    }

    if ((start_date && !end_date) || (!start_date && end_date)) {
      ErrorToast(ERROR_MESSAGES.missingDates);
      return;
    }

    if (start_date && end_date) {
      if (!start_date.trim() || !end_date.trim()) {
        ErrorToast(ERROR_MESSAGES.emptyDates);
        return;
      }

      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      if (endDateObj < startDateObj) {
        ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
        return;
      }
    }
    const commonParams: IGetCountryListParams = {};
    if (user_info?.is_super_admin === false) commonParams.hospital_property_id = user_info?.hospital_property?.id;
    if (start_date.trim() !== "") commonParams.start_date = start_date;
    if (end_date.trim() !== "") commonParams.end_date = end_date;
    if (sort_order.trim() !== "") commonParams.sort_order = sort_order;

    const request = await getNotificationsList(commonParams);
    if (request?.statusCode === 200) {
      setNotifications(request?.body?.data);
    } else {
      setNotifications([]);
    }
  };

  const handleResetFilters = () => {
    set_filters({ start_date: "", end_date: "", sort_order: " " });
    fetchNotifications();
    setfilterOpen(false);
  };

  const handleFilterChange = (name: string, value?: string) => {
    set_filters((prev_filters) => ({ ...prev_filters, [name]: value }));
  };

  useEffect(() => {
    if (document.body) {
      if (header_state === true) {
        document.body.classList.add("vertical-collpsed", "sidebar-enable");
      }
      if (header_state === false) {
        document.body.classList.remove("vertical-collpsed");
      }
    }
  }, [header_state]);

  const fetchNotifications = async () => {
    const params = user_info?.is_super_admin === false ? { hospital_property_id: user_info?.hospital_property?.id } : {}
    const { body } = await getNotificationsList(params);
    if (body?.data) {
      setNotifications(body?.data);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);
  return (
    <>
      <Header HeaderName="Activities" />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <div className="d-flex"></div>
                  <div className="page-title-right d-flex">
                    <div className="mr-2">
                      <button
                        type="button"
                        className="btn btn-outline-light"
                        onClick={() => setfilterOpen((filterOpen) => !filterOpen)}
                      >
                        <i className="mdi mdi-filter-outline"></i> Filter
                        <i className="mdi mdi-chevron-down" />
                      </button>
                      {filterOpen && (
                        <div className="filter-box">
                          <button
                            className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                            onClick={() => setfilterOpen(false)}
                          ><i className="mdi mdi-close" />
                          </button>
                          <div className="row">
                            <div className="col-6 mb-3">
                              <Label className="form-label">From Date</Label>
                              <CustomMUIDatePicker
                                name="start_date"
                                type="formik"
                                value={filters?.start_date}
                                setState={handleFilterChange}
                              />
                            </div>
                            <div className="col-6 mb-3">
                              <Label className="form-label">To Date</Label>
                              <CustomMUIDatePicker
                                name="end_date"
                                type="formik"
                                value={filters?.end_date}
                                setState={handleFilterChange}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <Label className="form-label">Filter By Order</Label>
                              <select
                                onChange={(event) => handleFilterChange("sort_order", event?.target.value)}
                                className="form-control"
                                name="sort_order"
                                value={filters.sort_order}
                              >
                                <option disabled value=" ">
                                  Select Order
                                </option>
                                <option style={{ color: "black" }} value="ASC">
                                  Ascending
                                </option>
                                <option style={{ color: "black" }} value="DESC">
                                  Decending
                                </option>
                              </select>
                            </div>
                            <div className="col-12 text-center">
                              <button
                                type="button"
                                onClick={() => handleFilterApply()}
                                className="btn btn-info medium-btn mr-2"
                              >
                                Apply
                              </button>
                              <button
                                onClick={() => handleResetFilters()}
                                type="button"
                                className="btn btn-secondary medium-btn"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col scroll-box">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Today</CardTitle>
                    {/* <h4 className="ca">Today</h4> */}
                    <ul className="list-activity">
                      {notifications && notifications.length > 0 ? (
                        notifications.map(
                          (
                            notification: ISingleNotification,
                            index: number
                          ) => (
                            <li key={index}>
                              <i className="mdi mdi-hospital-building text-primary font-size-18 mr-2"></i>
                              {notification?.notification_type}{" "}
                              {notification?.notification_text} by
                              <b className="mr-2">
                                {" "}
                                {notification?.created_by?.email}
                              </b>{" "}
                              <i className="bx bx-time mr-1"></i>
                              {formatDateToLocaleDateString(
                                notification?.created_on
                              )}
                            </li>
                          )
                        )
                      ) : (
                        <li>
                          <i className="mdi mdi-hospital-building text-primary font-size-18 mr-2"></i>
                          No Notifications found
                        </li>
                      )}
                    </ul>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activities;
