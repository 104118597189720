import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CardTitle } from "reactstrap";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { findTextInString } from "../../../../helpers/helperFunctions";
import NoRowsOverlay from "../../../../components/DataGrid/NoRowsOverlay";
import { LinearProgress } from "@mui/material";
import { grid_styles } from "../grid_styles";
import { updatePatient } from "../../../../redux/actions/patientAction";
import { v4 as uuidv4 } from "uuid";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";
 
const PatientMedical: React.FC<any> = ({ patient, fetchPatientData }) => {
  const initialRows = useMemo(
    () => [{ id: "1", name: "", details: "" }],
    []
  );
  type Row = (typeof initialRows)[number];
 
  const [page_number, setPage_number] = useState(1);
  const [per_page, setPer_page] = useState(0);
  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setrowId] = useState(null);
  const [globalSearch, setGlobalSearch] = useState("");
  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [
      { id: "1", name: "", details: "" },
      ...prevRows,
    ]);
  };
 
  const handleUpdatePatientMedical = useCallback(
    async ({
      id,
      name,
      details,
    }: {
      id: string;
      name: string;
      details: string;
    }) => {
      if (name.trim() === "") return ErrorToast("Name is required field!");
      if (details.trim() === "")
        return ErrorToast("Details is required field!");
      if (id === initialRows[0]?.id) {
        try {
          await updatePatient({
            id: patient?.id,
            medical: rows
              .filter((row) => row?.id !== "1")
              .concat({ id: uuidv4(), name, details }),
          });
          fetchPatientData();
          SuccessToast("Medical info added successfully");
        } catch (error) {
          SuccessToast("Error Saving Data, Try Again");
        }
      } else {
        let item_to_update = rows?.findIndex((obj: any) => {
          return obj.id === id;
        });
 
        rows[item_to_update].id = uuidv4();
        rows[item_to_update].name = name;
        rows[item_to_update].details = details;
        try {
          await updatePatient({
            id: patient?.id,
            medical: rows?.filter((row) => row?.id !== "1"),
          });
          fetchPatientData();
          SuccessToast("Medical info updated successfully");
        } catch (error) {
          SuccessToast("Error Saving Data, Try Again");
        }
      }
    },
    [rows, patient?.id, initialRows]
  );
 
  const handleDeletePatientMedical = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await updatePatient({
          id: patient?.id,
          medical: rows?.filter((row) => row?.id !== id),
        });
        fetchPatientData();
        SuccessToast("Medical Delete successfully");
      } catch (error) {
        SuccessToast("Error Saving Data, Try Again");
      }
    },
    [rows, patient?.id, fetchPatientData]
  );
 
  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeletePatientMedical({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeletePatientMedical]);
 
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
 
  const columns: any = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "name",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "details",
        headerName: "Detalis",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color={"primary"}
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdatePatientMedical({
                id: params.row.id.toString(),
                name: params.row.name.toString(),
                details: params.row.details.toString(),
              })
            }
          />,
 
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            disabled={params?.id === initialRows[0]?.id}
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal()
            }}
          />,
        ],
      },
    ],
    [
      toggleDeleteModal,
      handleUpdatePatientMedical,
      initialRows,
      rowId,
    ]
  );
 
  useEffect(() => {
    if (patient?.medical === undefined) {
      setRows([]);
    } else {
      setRows(patient?.medical);
    }
  }, [page_number, per_page, patient?.medical]);
 
  const filteredRows = useMemo(
    () => rows?.filter(({ name }) => findTextInString(name, globalSearch)),
    [rows, globalSearch]
  );
 
  const handleRowUpdateError = (error: any) => {};
 
  return (
    <>
    <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="patient medical"
        onConfirm={handleDeleteConfirmation}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <CardTitle className="h4 hospital-card-title text-primary">
            Medical
          </CardTitle>
        </div>
        <div>
          <button
            className="btn btn-primary mb-3"
            type="button"
            onClick={() => addRow()}
          >
            Add Row
          </button>
        </div>
      </div>
      <div style={{ height: "auto", width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          editMode="cell"
          rowHeight={35}
          columnHeaderHeight={35}
          disableRowSelectionOnClick={true}
          pagination={true}
          autoHeight={true}
          processRowUpdate={processRowUpdate}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5, page: 0 },
            },
          }}
          slots={{
            noRowsOverlay: NoRowsOverlay,
            loadingOverlay: LinearProgress,
          }}
          paginationMode="client"
          sx={grid_styles}
          onCellEditStart={onCellEditCommit}
          onProcessRowUpdateError={handleRowUpdateError}
        />
      </div>
    </>
  );
};
 
export default PatientMedical;