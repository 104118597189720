import { useState, useEffect, useCallback } from "react";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";
import {
  all_admitted_patients_by_hospital_property_id,
  all_patients_by_property_id,
  all_patients_without_pagination,
} from "../../redux/actions/patientAction";
import { ActionTypes } from "../../redux/types/patientTabTypes";
import { ErrorToast } from "../../utils/toastNotifications";
import PatientListGridView from "../../components/patientComponents/patientList/patientListGridView";
import PatientListCardView from "../../components/patientComponents/patientList/PatientListCardView";
import { ERROR_MESSAGES } from "../../helpers/constants";
import nopatient from "../../assets/images/nodata-found/patient.png";
import PatientHeader from "../../components/Header/PatientHeader";
import PatientSubHeader from "../../components/Header/PatientSubHeader";
import SvgFilesIcon from "../../components/SvgFiles/SvgFiles";

const PatientsList = ({ tab, handleBasicInfoEdit, handleCardView, setBasicInfoTab, setIsBasicInfoEditable }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [subTab, setsubTab] = useState("1");
  const [patients, setpatients] = useState([]);
  const [patient_loading, setpatient_loading] = useState(false);
  const [patients_data, setpatients_data] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    sort_order: " ",
  });
  const { user_info } = useSelector((state: any) => state.user);
  // const [tab, settab] = useState(user_info?.is_super_admin ? "allpatients" : "admitted");
  const { patientTabs, currentPatientActiveTab, patientMainTab } = useSelector((state: any) => state.patientTab);

  const subTabtoggle = (tab: string) => {
    if (subTab !== tab) setsubTab(tab);
  };

  const search_patient = (search_text: string) => {
    if (!search_text.trim()) {
      setpatients(patients_data);
    } else {
      const searchWords = search_text.trim().toLowerCase().split(' ');
      const filter_patients = patients_data.filter((patient: any) => {
        const fullName = `${patient?.first_name || ''} ${patient?.middle_name || ''} ${patient?.last_name || ''}`.toLowerCase();
        return searchWords.every((word: string) => fullName.includes(word));
      });
      setpatients(filter_patients);
    }
  };

  const toggle = useCallback(
    (tab: string) => {
      if (currentPatientActiveTab !== tab) {
        if (location?.pathname !== "/patients") {
          navigate("/patients");
        }
        dispatch({
          type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
          payload: { id: tab },
        });
      }
    },
    [currentPatientActiveTab, dispatch, location?.pathname, navigate]
  );

  const fetch_patients = useCallback(async () => {
    setpatient_loading(true);
    let request;

    if (
      user_info !== null &&
      user_info?.hospital_property !== null &&
      !user_info?.is_super_admin &&
      tab === "created"
    ) {
      request = await all_patients_by_property_id(
        user_info?.hospital_property?.id
      );
    } else if (
      user_info !== null &&
      user_info?.hospital_property !== null &&
      !user_info?.is_super_admin &&
      tab === "admitted"
    ) {
      request = await all_admitted_patients_by_hospital_property_id(
        user_info?.hospital_property?.id
      );
    } else {
      request = await all_patients_without_pagination();
    }

    if (request?.statusCode === 200) {
      const admittedPatients = request?.body?.data.filter((item: { admitted: any; discharge: any; }) => (item.admitted && !item.discharge) || tab !== "admitted");

      setpatients(admittedPatients);
      setpatients_data(admittedPatients);
      setpatient_loading(false);
    } else {
      setpatients([]);
      setpatients_data([]);
      setpatient_loading(false);
    }
  }, [tab, user_info, subTab]);

  useEffect(() => {
    if (patientMainTab === "1") {
      setBasicInfoTab(null);
      setIsBasicInfoEditable(false);
      fetch_patients();
    }
  }, [patientMainTab, fetch_patients, setBasicInfoTab, setIsBasicInfoEditable, tab]);

  const add_patient_tab = useCallback(
    (tab: any) => {
      dispatch({ type: ActionTypes.ADD_Patient_TAB, payload: tab });
    },
    [dispatch]
  );

  const handleFilterApply = async () => {
    setpatient_loading(true);
    const { start_date, end_date, sort_order } = filters;

    if ((start_date && !end_date) || (!start_date && end_date)) {
      ErrorToast(ERROR_MESSAGES.missingDates);
      setpatient_loading(false);
      return;
    }

    if (start_date && end_date) {
      if (!start_date.trim() || !end_date.trim()) {
        ErrorToast(ERROR_MESSAGES.emptyDates);
        setpatient_loading(false);
        return;
      }

      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      if (endDateObj < startDateObj) {
        ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
        setpatient_loading(false);
        return;
      }
    }

    const request = await all_patients_without_pagination(
      start_date,
      end_date,
      sort_order
    );
    if (request?.statusCode === 200) {
      setpatients(request?.body?.data);
      setpatients_data(request?.body?.data);
      setpatient_loading(false);
    } else {
      setpatients([]);
      setpatients_data([]);
      setpatient_loading(false);
    }
  };

  const handleFilterChange = (name: string, value?: string) => {
    setFilters((prev_filters) => ({ ...prev_filters, [name]: value }));
  };

  const handleFilterReset = () => {
    setFilters({ start_date: "", end_date: "", sort_order: " " });
    fetch_patients();
  };

  return (
    <>
      {patient_loading && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}
      <div className="row" style={{ height: "100%" }}>
        {/* <div className="col-12 col">
          <div className="page-title-box common-top-fix-title d-sm-flex align-items-center justify-content-between">
            <PatientHeader />
          </div>
        </div> */}

        <div className="page-title-box col-12 col scroll-bar-patient main-table-con">
          <div className="page-title-box common-top-con d-sm-flex justify-content-between">
            <div className="mr-2 patient-search-box d-sm-flex align-items-center">
              <label>Search Patient</label>
              <div className="search-box-wrapper">
                <SvgFilesIcon iconname={"search"} />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Patient"
                  onChange={(e: any) => search_patient(e.target.value)}
                />
              </div>
            </div>

            <div className="d-sm-flex">
              <div className="mr-2 patient-view-filter">
                <button
                  type="button"
                  onClick={() =>
                    setShowFilterBox((showFilterBox) => !showFilterBox)
                  }
                  className="btn btn-outline-light"
                >
                  <i className="mdi mdi-filter-outline"></i> Filter
                  <i className="mdi mdi-chevron-down" />
                </button>
                {showFilterBox && (
                  <div className="filter-box">
                    <button
                      className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                      onClick={() => setShowFilterBox(false)}
                    ><i className="mdi mdi-close" />
                    </button>
                    <div className="row">
                      <div className="col-6 mb-3">
                        <Label className="form-label">From Date</Label>
                        <CustomMUIDatePicker
                          name="start_date"
                          type="formik"
                          value={filters?.start_date}
                          setState={handleFilterChange}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <Label className="form-label">To Date</Label>
                        <CustomMUIDatePicker
                          name="end_date"
                          type="formik"
                          value={filters?.end_date}
                          setState={handleFilterChange}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <Label className="form-label">Filter By Order</Label>
                        <select
                          onChange={(event) => handleFilterChange("sort_order", event?.target.value)}
                          className="form-control"
                          name="sort_order"
                          value={filters.sort_order}
                        >
                          <option disabled value=" "> Select Order </option>
                          <option style={{ color: "black" }} value="ASC"> Ascending </option>
                          <option style={{ color: "black" }} value="DESC"> Decending </option>
                        </select>
                      </div>
                      <div className="col-12 text-center">
                        <button
                          type="button"
                          onClick={() => handleFilterApply()}
                          className="btn btn-info medium-btn mr-2"
                        >
                          Apply
                        </button>
                        <button
                          onClick={() => handleFilterReset()}
                          type="button"
                          className="btn btn-secondary medium-btn"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Nav tabs className="nav-tabs-custom list-view-tab patient-view-tab nav-justified">
                <NavItem>
                  <NavLink
                    className={subTab === "1" ? "active" : ""}
                    onClick={() => {
                      subTabtoggle("1");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      <i className="mdi mdi-grid"></i>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={subTab === "2" ? "active" : ""}
                    onClick={() => {
                      subTabtoggle("2");
                    }}
                  >
                    <span className="d-none d-sm-block">
                      {" "}
                      <i className="mdi mdi-format-list-bulleted"></i>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent activeTab={subTab} className="text-muted">
            {subTab === "1" &&
              < TabPane tabId="1">
                <div className="scroll-bar-patients patient-card-box">
                  {patients?.length > 0 ? (
                    patients.map((patient: any) => {
                      const tab_info = {
                        id: patient?.id,
                        name: patient?.first_name + " " + patient?.middle_name + " " + patient?.last_name
                      };

                      return (
                        // <div
                        //   className=""
                        //   key={patient?.id}
                        //   onClick={() => {
                        //     if (
                        //       patientTabs?.some(
                        //         (tab: any) => tab?.id === patient?.id
                        //       )
                        //     ) {
                        //       toggle(patient?.id);
                        //       dispatch({
                        //         type: ActionTypes.SET_Patient_TAB,
                        //         payload: tab_info,
                        //       });
                        //       setIsBasicInfoEditable(false);
                        //       setBasicInfoTab(null);
                        //     } else {
                        //       add_patient_tab(tab_info);
                        //       toggle(patient?.id);
                        //       dispatch({
                        //         type: ActionTypes.SET_Patient_TAB,
                        //         payload: tab_info,
                        //       });
                        //       setIsBasicInfoEditable(false);
                        //       setBasicInfoTab(null);
                        //     }
                        //   }}
                        //   // style={{ cursor: "pointer" }}
                        // >
                        <PatientListCardView
                          currentPatientActiveTab={currentPatientActiveTab}
                          patient={patient}
                          handleEdit={handleBasicInfoEdit}
                          handleview={handleCardView}
                        />
                        // </div>
                      );
                    })
                  ) : (
                    <div
                      className="no-data-found"
                    >
                      <img src={nopatient} width={80}></img>
                      <h4 className="mt-2">No Patients Found</h4>
                    </div>
                  )}
                </div>
              </TabPane>
            }
            {subTab === "2" &&
              <TabPane tabId="2">
                <div className="row scroll-bar-patients patient-list-view-section">
                  <div className="col-md-12">
                    <Card>
                      <CardBody>
                        <div className="">
                          <table className="table table-bordered  table-nowrap mb-0 table-list">
                            <thead className="table-light">
                              <tr>
                                <th>Patients Info</th>
                                <th>Contact Persoin</th>
                                <th>Conatct Number</th>
                                <th>Active Property</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {patients?.length > 0 ? (
                                patients.map((patient: any) => {
                                  const tab_info = {
                                    id: patient?.id,
                                    name: patient?.first_name + " " + patient?.middle_name + " " + patient?.last_name
                                  };
                                  return (
                                    <tr
                                      style={{ cursor: 'pointer' }}
                                      key={patient?.id}
                                      onClick={() => {
                                        if (patientTabs?.some((tab: any) => tab?.id === patient?.id)) {
                                          toggle(patient?.id);
                                          dispatch({ type: ActionTypes.SET_Patient_TAB, payload: tab_info });
                                        } else {
                                          add_patient_tab(tab_info);
                                          toggle(patient?.id);
                                          dispatch({ type: ActionTypes.SET_Patient_TAB, payload: tab_info });
                                        }
                                      }}
                                    >
                                      <PatientListGridView
                                        currentPatientActiveTab={currentPatientActiveTab}
                                        patient={patient}
                                        handleEdit={handleBasicInfoEdit}
                                      />
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      marginTop: 250,
                                    }}
                                  >
                                    <img src={nopatient} width={80}></img>
                                    <h4 className="mt-2">No Patients Found</h4>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </TabPane>
            }
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default PatientsList;