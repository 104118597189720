import { LinearProgress, Select, MenuItem } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import NoRowsOverlay from "../../../../components/DataGrid/NoRowsOverlay";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import { grid_styles } from "../grid_styles";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { IDiseaseOptions } from "../../CreatePatient";
import { getAdmissionById } from "../../../../redux/actions/patientAction";
import { getHabitTypesList } from "../../../../redux/actions/habitTypeAction";
import { deletePatientAdmissionHabits, getPatientAdmissionHabitsByPatientAdmissionId, postPatientAdmissionHabits, putPatientAdmissionHabits } from "../../../../redux/actions/patientAdmissionHabits";
import { IPatientAdmissionHabitsTypes } from "../../../../redux/types/patientAdmissionHabitsTypes";

const Habits: React.FC<any> = ({
  selected_admission,
  setselected_admission,
}) => {
  const initialRows = useMemo(
    () => [
      {
        id: "1",
        habit_type: { id: "", name: " " },
        quantity: "",
        frequency: "",
        how_long: "",
      },
    ],
    []
  );

  type Row = (typeof initialRows)[number];
  const { user_info } = useSelector((state: any) => state?.user);

  const [page_number] = useState(1);
  const [per_page] = useState(8);
  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setrowId] = useState(null);
  const [habit_types, set_habit_types] = useState([]);
  const isCellEditable = true;
  const handleRowUpdateError = (error: any) => { };

  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [
      {
        id: "1",
        habit_type: { id: "", name: " " },
        quantity: "",
        frequency: "",
        how_long: "",
      },
      ...prevRows,
    ]);
  };

  const getHabitsTypes = async () => {
    const { body } = await getHabitTypesList({});
    if (body) set_habit_types(body?.data);
    else set_habit_types([]);
  };

  useEffect(() => {
    getHabitsTypes();
  }, []);

  const refresh_selected_admission = useCallback(async () => {
    let request = await getAdmissionById(selected_admission?.id);

    if (request?.statusCode === 200) {
      setselected_admission(request?.body);
    } else {
      setselected_admission(selected_admission);
    }
  }, [selected_admission, setselected_admission]);


  const getHabitsByPatientAdmissionId = useCallback(async () => {
    if (selected_admission !== null) {
      const request = await getPatientAdmissionHabitsByPatientAdmissionId(selected_admission?.id);
      if (request?.statusCode === 200) setRows(request?.body?.data);
      else setRows([]);
    }
  }, [selected_admission]);

  useEffect(() => {
    getHabitsByPatientAdmissionId();
  }, [getHabitsByPatientAdmissionId]);


  const handleUpdateHabits = useCallback(async ({ id, habit_type_id, quantity, frequency, how_long, }: IPatientAdmissionHabitsTypes) => {

    try {
      if (!selected_admission?.id?.trim()) throw new Error("Please select a hospital first!");
      if (!habit_type_id?.trim()) throw new Error("Habit type is required field!");
      if (!quantity?.trim()) throw new Error("Quanitity is a required field!");
      if (!frequency?.trim()) throw new Error("Frequncy is a required field!");
      if (!how_long?.trim()) throw new Error("How Long is a required field!");

      const isInitialRowId = id === initialRows[0]?.id;

      const values = {
        patient_admission_id: selected_admission?.id,
        habit_type_id,
        quantity,
        frequency,
        how_long,
        id: isInitialRowId ? undefined : id,
      };

      const response = isInitialRowId
        ? await postPatientAdmissionHabits(values)
        : await putPatientAdmissionHabits(values);

      if (response?.statusCode === 200) {
        await refresh_selected_admission();
        SuccessToast(`Patient Admission Habits ${isInitialRowId ? "Added" : "Updated"} Successfully!`);
        setrowId(null);
      } else if (response?.statusCode === 400) {
        ErrorToast(response?.errors[0]?.msg);
      } else {
        ErrorToast("Unknown Error Occurred!");
      }
    } catch (error: any) {
      ErrorToast(error?.message);
    }

  },
    [initialRows, selected_admission?.id, refresh_selected_admission]
  );

  const handleDeleteHabits = useCallback(async ({ id }: { id: string }) => {
    try {
      const response = await deletePatientAdmissionHabits(id);
      if (response?.statusCode === 200) {
        await refresh_selected_admission();
        SuccessToast("Patient Admission Habits Deleted Successfully!");
      } else if (response?.statusCode === 400)
        ErrorToast(response?.errors[0]?.msg);
      else ErrorToast("Unknown Error Occurred!");
    } catch (error: any) {
      ErrorToast(error?.message);
    }
  },
    [refresh_selected_admission]
  );
  const columns: any = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "habit_type",
        headerName: "Habit Type",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: false,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (selectedHabitsTypeId: string) => {
            setrowId(params.id);

            const selectedHabitsType = habit_types?.find(({ id }) => id === selectedHabitsTypeId);
            if (!selectedHabitsType) return;

            const { id, name } = selectedHabitsType;

            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;

            const isParamsIdOne = params.id === 1;
            const row = params.row || {};
            const updatedRow = {
              ...rows[rowIndex],
              habit_type: { id, name },
              quantity: rows[rowIndex]?.quantity?.trim() !== "" && isParamsIdOne ? rows[rowIndex]?.quantity : row?.quantity,
              frequency: rows[rowIndex]?.frequency?.trim() !== "" && isParamsIdOne ? rows[rowIndex]?.frequency : row?.frequency,
              how_long: rows[rowIndex]?.how_long?.trim() !== "" && isParamsIdOne ? rows[rowIndex]?.how_long : row?.how_long,
            };

            const updatedRows = [...rows.slice(0, rowIndex), updatedRow, ...rows.slice(rowIndex + 1)];
            setRows(updatedRows);
          };
          return (
            <div style={{ width: "100%" }}>
              <Select
                MenuProps={{ autoFocus: false }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0 !important",
                  },
                  "& :focus": {
                    outline: "none !important",
                    border: "0 !important",
                    boxShadow: "none !important",
                  },
                  width: "100%",
                  color: "#a6b0cf",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
                renderValue={() => params?.value?.name?.trim() === "" ? "Select Habits Type" : params?.value?.name}
                value={params?.value?.name || params?.row?.habits_type?.name}
                onChange={(event) => handleChange(event.target.value)}
              >
                <MenuItem value=" " disabled>
                  <em>Select Habits Type</em>
                </MenuItem>
                {habit_types && habit_types?.length > 0 ?
                  habit_types?.map(({ name, id }: IDiseaseOptions) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  )) :
                  (
                    <MenuItem disabled>
                      No Habits Types Found
                    </MenuItem>
                  )}
              </Select>
            </div>
          );
        },
      },
      {
        field: "quantity",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: isCellEditable,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "frequency",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: isCellEditable,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "how_long",
        headerName: "How Long",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: isCellEditable,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        type: "actions",
        width: 150,
        getActions:
          user_info?.hospital_property?.id ===
            selected_admission?.hospital_property?.id &&
            selected_admission?.admitted &&
            !selected_admission?.discharged
            ? (params: any) => [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                color={"primary"}
                disabled={params?.id !== rowId}
                onClick={() =>
                  handleUpdateHabits({
                    id: params.row.id.toString(),
                    habit_type_id: params.row.habit_type?.id,
                    frequency: params.row.frequency,
                    quantity: params.row.quantity,
                    how_long: params.row.how_long,
                  })
                }
              />,

              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                color="error"
                disabled={params?.id === initialRows[0]?.id}
                onClick={() =>
                  handleDeleteHabits({
                    id: params.row.id.toString(),
                  })
                }
              />,
            ]
            : (params: any) => [],
      },
    ],
    [habit_types, handleDeleteHabits, handleUpdateHabits, initialRows, isCellEditable, rowId, rows, selected_admission?.admitted, selected_admission?.discharged, selected_admission?.hospital_property?.id, user_info?.hospital_property?.id]
  );

  return (
    <>
      <div className="add-row-btn">
        {(true || user_info?.hospital_property?.id ===
          selected_admission?.hospital_property?.id ) &&
          selected_admission?.admitted &&
          !selected_admission?.discharged && (
            <button
              className="btn btn-primary mb-3"
              type="button"
              onClick={() => addRow()}
            >
              Add Row
            </button>
          )}
      </div>
      <div className="row">
        <div className="col-12">
          <div style={{ height: "auto", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="cell"
              rowHeight={35}
              columnHeaderHeight={35}
              autoHeight={true}
              disableRowSelectionOnClick={true}
              pagination={true}
              processRowUpdate={processRowUpdate}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 5, page: 0 },
                },
              }}
              slots={{
                noRowsOverlay: NoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              paginationMode="client"
              sx={grid_styles}
              onCellEditStart={onCellEditCommit}
              onProcessRowUpdateError={handleRowUpdateError}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Habits;
