import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { getDoctorListByRole } from "../../redux/actions/doctorAction";
import { getDoctorSpecialityTypesList } from "../../redux/actions/doctorSpecialityAction";
import { SpecialityTypes } from "../users/components/RegisterForm";
import { useSelector } from "react-redux";
import doctorMaleProfilePhoto from "../../assets/images/doctor.png";
import doctorFemaleProfilePhoto from "../../assets/images/female-doctor.png";
import SvgFilesIcon from "../../components/SvgFiles/SvgFiles";
import { fetch_all_users } from "../../redux/actions/userAction";
import DoctorProfiles from "./DoctorProfiles";
import { Nav, NavItem, NavLink } from "reactstrap";

interface Doctor {
    id: string;
    name: string;
    gender: string;
    mobile: string;
    specialist: string[];
}

interface SpecialityOption {
    value: string;
    label: string;
}

interface UserInfo {
    hospital_property: {
        hospital: {
            id: string;
        };
    };
    is_super_admin: boolean;
    is_admin: boolean;
}

interface RootState {
    user: {
        user_info: UserInfo;
    };
}

const DoctorsList: React.FC = () => {
    const [doctorTypes, setDoctorTypes] = useState<Doctor[]>([]);
    const [specialityoptions, setSpecialityOptions] = useState<SpecialityOption[]>([]);
    const { user_info } = useSelector((state: RootState) => state.user);
    const [allUserDoctor, setAllUserDoctor] = useState<Doctor[]>([]);
    const [activeTab, setActiveTab] = useState<string>("allDoctors");
    const [doctorTabs, setDoctorTabs] = useState<Doctor[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { body } = await getDoctorListByRole({ role: "doctor", hospital_id: user_info?.hospital_property?.hospital?.id });
                if (body) setDoctorTypes(body?.data || []);
                else setDoctorTypes([]);

                const speciality = await getDoctorSpecialityTypesList({});
                if (speciality?.statusCode === 200) {
                    const optionsAll = speciality?.body?.data?.map((spec: SpecialityTypes) => ({
                        value: spec?.id,
                        label: spec?.name
                    })) || [];
                    setSpecialityOptions(optionsAll);
                }
            } catch (error) {
                console.error("Error fetching doctor types:", error);
            }
        };

        fetchData();
    }, [user_info]);

    const fetchUsers = async () => {
        try {
            let response = await fetch_all_users({});
            let allUsers = response?.body?.data || [];
            let filteredDoctors = allUsers?.filter((user: any) => user.user_type?.user_type.toLowerCase() === 'doctor');
            setAllUserDoctor(filteredDoctors);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const openDoctorTab = (doctor: Doctor) => {
        setActiveTab(doctor.id);
        if (!doctorTabs.some(tab => tab.id === doctor.id)) {
            setDoctorTabs([...doctorTabs, doctor]);
        }
    };

    const closeDoctorTab = (doctorId: string) => {
        const newTabs = doctorTabs.filter(tab => tab.id !== doctorId);
        setDoctorTabs(newTabs);
        if (activeTab === doctorId && newTabs.length > 0) {
            setActiveTab(newTabs[0].id);
        } else if (newTabs.length === 0) {
            setActiveTab("allDoctors");
        }
    };

    const DoctorCard: React.FC<{ doctor: Doctor }> = ({ doctor }) => {
        return (
            <div className="common-box" onClick={() => openDoctorTab(doctor)}>
                <img src={doctor.gender === "male" ? doctorMaleProfilePhoto : doctorFemaleProfilePhoto} alt="" />
                <div className="details-box">
                    <h4>{doctor.name}</h4>
                </div>
            </div>
        );
    };

    const DoctorDetails: React.FC<{ doctor: any }> = ({ doctor }) => {
        if (!doctor) return null;
        return (
            <div>
                <DoctorProfiles doctor={doctor} />
            </div>
        );
    };

    return (
        <>
            <Header HeaderName="Doctors List" />
            <Sidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col">
                                <div className="doctor-tabs-code page-title-box common-top-fix-title d-sm-flex align-items-center justify-content-between only-create-title ">
                                    <div className="patient-open-tab">
                                        <Nav tabs className="nav-tabs-custom">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={`tab ${activeTab === "allDoctors" ? "active" : ""}`} onClick={() => setActiveTab("allDoctors")}
                                                >
                                                    <span className="d-none d-sm-block hospital-name">
                                                        All Doctors
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <div className="tabs">
                                                {doctorTabs.map((doctor) => (
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={`tab ${activeTab === doctor?.id ? "active" : ""}`}>
                                                            <div key={doctor.id} >
                                                                <span onClick={() => setActiveTab(doctor?.id)}>{doctor?.name}</span>
                                                                <i
                                                                    style={{
                                                                        color: "#3D79F7"
                                                                    }}
                                                                    className="mdi mdi-close"
                                                                    onClick={() => closeDoctorTab(doctor?.id)}
                                                                ></i>
                                                            </div>
                                                        </NavLink>
                                                    </NavItem>
                                                ))}
                                            </div>
                                        </Nav>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    {activeTab === "allDoctors" ? (
                                        <div className="row">
                                            {doctorTypes?.length > 0 ? (
                                                <div className="doctor-list-section">
                                                    {(user_info?.is_super_admin || user_info?.is_admin ? allUserDoctor : doctorTypes)?.map((doctor) => (
                                                        <div className="common-box" onClick={() => openDoctorTab(doctor)} key={doctor?.id}>
                                                            <img src={doctor.gender === "male" ? doctorMaleProfilePhoto : doctorFemaleProfilePhoto} alt="" />
                                                            <div className="details-box">
                                                                <h4>{doctor?.name}</h4>
                                                                <div className="contact-box">
                                                                    <span>Contact Number</span>
                                                                    <p>{doctor?.mobile}</p>
                                                                </div>
                                                                <div className="department-box">
                                                                    {doctor.specialist?.map((speciality) => (
                                                                        <span key={speciality}>{specialityoptions?.find((option) => option?.value === speciality)?.label}</span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <SvgFilesIcon iconname={"nodatafound"} />
                                            )}
                                        </div>
                                    ) : (
                                        // <DoctorDetails doctor={doctorTabs.find(tab => tab.id === activeTab)} />
                                        <DoctorProfiles doctor={doctorTabs?.find(tab => tab.id === activeTab)} />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default DoctorsList;
