import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Input, Label } from "reactstrap";
import { getCountryList } from "../../../redux/actions/countryAction";
import { Country } from "../../../redux/types/countryTypes";
import { getCityByStateList } from "../../../redux/actions/cityAction";
import { IStateOptions } from "../../../redux/types/stateTypes";
import { Field } from "formik";
import { getStatesByCountry } from "../../../redux/actions/stateActions";
import { ICitiesOptions } from "../../../redux/types/cityTypes";
import { all_hospitals_without_pagination } from "../../../redux/actions/hospitalAction";
import { hospital_property_by_hospital } from "../../../redux/actions/hospitalPropertyAction";
import { useSelector } from "react-redux";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomMUIDatePicker from "../../UI/CustomMUIDatePicker";
import { calculateAge } from "../../../helpers/helperFunctions";
const BasicInfo: React.FC<any> = ({
  handlePrevious,
  subTabSecond,
  dirty,
  isValid,
  subTabs,
  handleNext,
  errors,
  handleBlur,
  handleChange,
  values,
  touched,
  setFieldValue,
}) => {
  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const [stateOptions, setStateOptions] = useState<IStateOptions[]>([]);
  const [cityOptions, setCityOptions] = useState<ICitiesOptions[]>([]);
  const [all_hospitals, set_all_hospitals] = useState([]);
  const [all_hospitals_properties, set_all_hospitals_properties] = useState([]);
  const { user_info } = useSelector((state: any) => state.user);
  const [age, setAge] = useState('');

  useEffect(() => {
    if (values.birth_date) {
      setFieldValue("age", '');
      const calculatedAge = calculateAge(values.birth_date);
      setAge(calculatedAge);
      setFieldValue("age", calculatedAge);
    }
  }, [values.birth_date]);

  const fetch_states = useCallback(
    async (country_id: string, setFieldValue: any) => {
      if (country_id !== undefined) {
        const request = await getStatesByCountry({ country_id });
        if (request?.statusCode === 200) {
          if (request?.body?.data.length === 0) {
            setFieldValue("state_id", "");
            setFieldValue("city_id", "");
          }
          setStateOptions(
            request?.body?.data?.map((state: any) => {
              return {
                id: state?.id,
                state: state?.state,
              };
            })
          );
        } else {
          setStateOptions([]);
          setFieldValue("state_id", "");
          setFieldValue("city_id", "");
        }
      }
    },
    []
  );

  const fetch_city = useCallback(
    async (state_id: string, setFieldValue: any) => {
      if (state_id !== undefined) {
        const request = await getCityByStateList({ state_id });
        if (request?.statusCode === 200) {
          if (request?.body?.data.length === 0) {
            setFieldValue("city_id", "");
            setFieldValue("pincode", "");
          }
          setCityOptions(
            request?.body?.data?.map((city: any) => {
              return {
                id: city?.id,
                city: city?.city,
                zipcode: city?.zipcode
              };
            })
          );
        } else {
          setFieldValue("city_id", "");
          setFieldValue("pincode", "");
          setCityOptions([]);
        }
      }
    },
    []
  );

  useEffect(() => {
    /* FETCH ALL COUNTRIES */
    async function fetchCountryData() {
      const { body } = await getCountryList({});
      const updatedCountryList: Country[] = body?.data?.map((e: any) => ({
        country: e?.country,
        id: e?.id,
      }));
      setCountryOptions(updatedCountryList);
    }

    if (subTabSecond === 1) {
      fetchCountryData();
    }
  }, [subTabSecond]);

  const fetch_hospitals = async () => {
    try {
      const request = await all_hospitals_without_pagination();
      if (request?.statusCode === 200) {
        set_all_hospitals(request?.body?.data);
      } else {
        set_all_hospitals([]);
      }
    } catch (error) {
      console.error('Error fetch_hospitals:', error);
      set_all_hospitals([]);
    }
  };


  const fetchHospitalProperties = async (selectedHospital: string) => {
    try {
      if (selectedHospital.trim() !== "") {
        const request = await hospital_property_by_hospital(selectedHospital);
        if (request?.statusCode === 200) {
          set_all_hospitals_properties(request?.body?.data);
          setFieldValue("hospital_property_id", request?.body?.data[0]?.id);
        } else {
          set_all_hospitals_properties([]);
          setFieldValue("hospital_property_id", "");
        }
      }
    } catch (error) {
      console.error('Error : fetchHospitalProperties', error);
    }
  };

  useEffect(() => {
    if (values?.hospital_id?.trim() !== "") {
      fetchHospitalProperties(values?.hospital_id);
    }
  }, [values?.hospital_id]);

  useEffect(() => {
    setAge('');
    setFieldValue("age", '');
    fetch_hospitals();
  }, []);


  const handleChangeCity = (e: any) => {
    setFieldValue("city_id", e.target.value);
    const Zipcode = cityOptions.find((city) => city.id === e.target.value)?.zipcode || "";
    setFieldValue("pincode", Zipcode);
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="h4 hospital-card-title text-primary">
          Basic Info
        </CardTitle>
        <div className="row">
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">First Name</Label>
              <Input
                name="first_name"
                className={`"form-control" ${(errors.first_name && touched.first_name) && "error"}`}
                type="text"
                placeholder="Enter Name"
                value={values?.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.first_name && touched.first_name && (
                <span className="error">{errors.first_name}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Middle Name</Label>
              <Input
                name="middle_name"
                className={`"form-control" ${(errors.middle_name && touched.middle_name) && "error"}`}
                type="text"
                placeholder="Enter Middle Name"
                value={values?.middle_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors?.middle_name && touched?.middle_name && (
                <span className="error">{errors?.middle_name}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Last Name</Label>
              <Input
                name="last_name"
                className={`"form-control" ${(errors.last_name && touched.last_name) && "error"}`}
                type="text"
                placeholder="Enter Last Name"
                value={values?.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.last_name && touched.last_name && (
                <span className="error">{errors.last_name}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Adhar Card No.</Label>
              <Input
                name="aadhar_card_no"
                className={`"form-control" ${(errors.aadhar_card_no && touched.aadhar_card_no) && "error"}`}
                type="text"
                placeholder="Enter Adhar (12 digit)"
                value={values?.aadhar_card_no}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.aadhar_card_no && touched.aadhar_card_no && (
                <span className="error">{errors.aadhar_card_no}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Aayushman Card No.</Label>
              <Input
                name="ayushman_card_no"
                className={`"form-control" ${(errors.ayushman_card_no && touched.ayushman_card_no) && "error"}`}
                type="text"
                placeholder="Enter Ayushman Card (12-15 digit)"
                value={values?.ayushman_card_no}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.ayushman_card_no && touched.ayushman_card_no && (
                <span className="error">{errors.ayushman_card_no}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Phone</Label>
              <Input
                name="phone_number"
                className={`"form-control" ${(errors.phone_number && touched.phone_number) && "error"}`}
                type="text"
                placeholder="Enter Phone (10 digit)"
                value={values?.phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.phone_number && touched.phone_number && (
                <span className="error">{errors.phone_number}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Email</Label>
              <Input
                name="email"
                className={`"form-control" ${(errors.email && touched.email) && "error"}`}
                type="text"
                placeholder="Enter Email"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.email && touched.email && (
                <span className="error">{errors.email}</span>
              )} */}
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Blood Group</Label>
              <Input
                name="blood_group"
                className={`"form-control" ${(errors.blood_group && touched.blood_group) && "error"}`}
                type="text"
                placeholder="Enter Blood Group"
                value={values?.blood_group}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.blood_group && touched.blood_group && (
                <span className="error">{errors.blood_group}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Birth Date</Label>
              <CustomMUIDatePicker
                className={`"form-control" ${(errors.birth_date && touched.birth_date) && "error"}`}
                maxDate={new Date()}
                name="birth_date"
                value={values?.birth_date}
                setState={setFieldValue}
                sx={{
                  ".MuiOutlinedInput-input": {
                    color: "#fff",
                    height: "4px",
                    fontWeight: 400,
                    fontSize: "0.8125rem"
                  }
                }}
                type="formik"
              />
              {/* {errors.birth_date && touched.birth_date && (
                <span className="error">{errors.birth_date}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Age (Years)</Label>
              <Input
                name="age"
                className={`"form-control" ${(errors.age && touched.age) && "error"}`}
                type="text"
                placeholder="Enter Age"
                value={values?.age || ''}
                onChange={(e) => {
                  const newValue = e.target.value.toUpperCase();
                  const validValue = newValue.replace(/[^0-9\.]/g, '');
                  const numericValue = parseFloat(validValue)
                  if (!isNaN(numericValue) && parseFloat(validValue) <= 125) {
                    handleChange({
                      target: {
                        name: "age",
                        value: validValue
                      }
                    });
                  } else {
                    handleChange({
                      target: {
                        name: "age",
                        value: ""
                      }
                    });
                  }
                }}
                onBlur={handleBlur}
              />
              {/* {errors.age && touched.age && (
                <span className="error">{errors.age}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Weight (kg)</Label>
              <Input
                name="weight"
                className={`"form-control" ${(errors.weight && touched.weight) && "error"}`}
                type="text"
                placeholder="Enter Weight"
                value={values?.weight}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.weight && touched.weight && (
                <span className="error">{errors.weight}</span>
              )} */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Height (ft/cm)</Label>
              <Input
                name="height"
                className={`"form-control" ${(errors.height && touched.height) && "error"}`}
                type="text"
                placeholder="Enter Height"
                value={values?.height}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.height && touched.height && (
                <span className="error">{errors.height}</span>
              )} */}
            </div>
          </div>

          <div className="col-md-3">
            <div className="mb-3">
              <Label className="form-label">Sex</Label>
              <div className="d-block mt-2">
                <div className="form-check d-inline-block mr-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sex"
                    value="male"
                    id="sexRadios11"
                    checked={values?.sex === "male"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label className="form-check-label" htmlFor="sexRadios11">
                    Male
                  </label>
                </div>
                <div className="form-check d-inline-block ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sex"
                    id="sexRadios22"
                    value="female"
                    checked={values?.sex === "female"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label className="form-check-label" htmlFor="sexRadios22">
                    Female
                  </label>
                </div>
              </div>
            </div>
            {/* {errors?.sex && touched?.sex && (
              <span className="error">{errors?.sex}</span>
            )} */}
          </div>
          <div className="col-md-3">
            <div className="">
              <Label className="form-label"> Marital status</Label>
              <div className="d-block mt-2">
                <div className="form-check d-inline-block mr-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="maritial_status"
                    id="maritial_statusRadio11"
                    value="single"
                    checked={values?.maritial_status === "single"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="maritial_statusRadio11"
                  >
                    Single
                  </label>
                </div>
                <div className="form-check d-inline-block ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="maritial_status"
                    id="maritial_statusRadio22"
                    value="married"
                    checked={values?.maritial_status === "married"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="maritial_statusRadio22"
                  >
                    Married
                  </label>
                </div>
              </div>
            </div>
            {/* {errors?.maritial_status && touched?.maritial_status && (
              <span className="error">{errors?.maritial_status}</span>
            )} */}
          </div>
          {(user_info?.is_super_admin && <>
            <div className="col-3">
              <div className="">
                <Label className="form-label">Hospital</Label>
                <Field
                  as="select"
                  className="form-control"
                  id="hospital_id"
                  value={values?.hospital_id}
                  name="hospital_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select a Hospital</option>
                  {all_hospitals && all_hospitals?.length > 0 ? (
                    all_hospitals?.map(({ id, name }, index) => (
                      <option key={index} style={{ color: "#000" }} value={id}>
                        {name}
                      </option>
                    ))
                  ) : (
                    <option style={{ color: "#000" }} value={undefined} disabled>
                      No Hospitals found
                    </option>
                  )}
                </Field>
                {/* {errors?.hospital_id && touched?.hospital_id && (
                  <span className="error">{errors?.hospital_id}</span>
                )} */}
              </div>
            </div>
            <div className="col-3">
              <div className="">
                <Label className="form-label">Hospital Property</Label>
                <Field
                  as="select"
                  className="form-control"
                  id="hospital_property_id"
                  value={values?.hospital_property_id}
                  name="hospital_property_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option hidden>Select Hospital Property</option>
                  {all_hospitals_properties &&
                    all_hospitals_properties?.length > 0 ? (
                    all_hospitals_properties?.map(({ id, landmark }, index) => (
                      <option key={index} style={{ color: "#000" }} value={id}>
                        {landmark}
                      </option>
                    ))
                  ) : (
                    <option style={{ color: "#000" }} disabled value={undefined}>
                      No Hospital Properties found
                    </option>
                  )}
                </Field>
                {/* {errors?.hospital_property_id && touched?.hospital_property_id && (
                  <span className="error">{errors?.hospital_property_id}</span>
                )} */}
              </div>
            </div></>)}
        </div>
        <hr></hr>
        <div className="row">
          <h6>Address Detail</h6>
          <div className="col-12">
            <div className="mb-3">
              <Label className="form-label">Address</Label>
              <Input
                name="address"
                className={`"form-control" ${(errors.address && touched.address) && "error"}`}
                type="text"
                placeholder="Enter Address"
                value={values?.address || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.address && touched.address && (
                <span className="error">{errors.address}</span>
              )} */}
            </div>
          </div>
          <div className="col-3">
            <div className="">
              <Label className="form-label">Country</Label>
              <Field
                as="select"
                className={`form-control ${errors.country_id && touched.country_id ? "error dropdown-error" : ""}`}
                id="country_id"
                name="country_id"
                value={values?.country_id || ''}
                onChange={async (e: any) => {
                  handleChange(e);
                  if (e.target.value !== undefined)
                    await fetch_states(e?.target?.value, setFieldValue);
                }}
                onBlur={handleBlur}
              >
                <option value={undefined}>Select Country</option>
                {countryOptions?.map(({ country, id }) => (
                  <option key={id} value={id}>
                    {country}
                  </option>
                ))}
              </Field>
              {/* {errors.country_id && touched.country_id && (
                <span className="error">{errors.country_id}</span>
              )} */}
            </div>
          </div>
          <div className="col-3">
            <div className="">
              <Label className="form-label">State</Label>
              <select
                className={`form-control ${errors.state_id && touched.state_id ? "error dropdown-error" : ""}`}
                name="state_id"
                id="state_id"
                value={values?.state_id || ''}
                onChange={async (e: any) => {
                  if (stateOptions?.length === 0) handleChange(undefined);
                  handleChange(e);
                  if (e.target.value !== undefined)
                    await fetch_city(e?.target?.value, setFieldValue);
                }}
                onBlur={handleBlur}
              >
                <option value={undefined}>Select a state</option>
                {stateOptions?.map(({ state, id }) => (
                  <option key={id} value={id}>
                    {state}
                  </option>
                ))}
              </select>
              {/* {errors?.state_id && touched?.state_id && (
                <span className="error">{errors?.state_id}</span>
              )} */}
            </div>
          </div>
          <div className="col-3">
            <div className="">
              <Label className="form-label">City</Label>
              <Field
                as="select"
                className={`form-control ${errors.city_id && touched.city_id ? "error dropdown-error" : ""}`}
                id="city_id"
                value={values?.city_id || ''}
                name="city_id"
                onChange={handleChangeCity}
                onBlur={handleBlur}
              >
                <option value="">Select a city</option>
                {cityOptions?.map(({ city, id }) => (
                  <option key={id} value={id}>
                    {city}
                  </option>
                ))}
              </Field>
              {/* {errors?.city_id && touched?.city_id && (
                <span className="error">{errors?.city_id}</span>
              )} */}
            </div>
          </div>
          <div className="col-3">
            <div className="">
              <Label className="form-label">Pincode</Label>
              <Input
                name="pincode"
                className={`"form-control" ${(errors.pincode && touched.pincode) && "error"}`}
                type="text"
                placeholder="Enter Pincode (6 digit)"
                value={values?.pincode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.pincode && touched.pincode && (
                <span className="error">{errors.pincode}</span>
              )} */}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <h6>Emergency Contact</h6>
          <div className="col-4">
            <div className="">
              <Label className="form-label">Name</Label>
              <Input
                name="emergency_contact_name"
                className={`"form-control" ${(errors.emergency_contact_name && touched.emergency_contact_name) && "error"}`}
                type="text"
                placeholder="Enter Emergency Contact Name"
                value={values?.emergency_contact_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors.emergency_contact_name &&
                touched.emergency_contact_name && (
                  <span className="error">{errors.emergency_contact_name}</span>
                )} */}
            </div>
          </div>
          <div className="col-4">
            <div className="">
              <Label className="form-label">Relationship</Label>
              <select
                name="emergency_contact_relationship"
                className="form-control"
                value={values?.emergency_contact_relationship || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="" disabled>
                  Select Relationship
                </option>
                <option value="father">Father</option>
                <option value="mother">Mother</option>
                <option value="wife">Wife</option>
                <option value="husband">Husband</option>
                <option value="son">Son</option>
                <option value="daughter">Daughter</option>
                <option value="sibling">Sibling</option>
              </select>
              {/* {errors?.emergency_contact_relationship &&
                touched?.emergency_contact_relationship && (
                  <span className="error">
                    {errors?.emergency_contact_relationship}
                  </span>
                )} */}
            </div>
          </div>
          <div className="col-4">
            <div className="">
              <Label className="form-label">Contact No</Label>
              <Input
                name="emergency_contact_no"
                className={`"form-control" ${(errors.emergency_contact_no && touched.emergency_contact_no) && "error"}`}
                type="text"
                placeholder="Enter Contact No"
                value={values?.emergency_contact_no}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* {errors?.emergency_contact_no &&
                touched?.emergency_contact_no && (
                  <span className="error">{errors?.emergency_contact_no}</span>
                )} */}
            </div>
          </div>
        </div>
        <div className="col-12 button-box text-center">
          <button
            type="button"
            onClick={() => handlePrevious()}
            style={{
              display: subTabSecond === 1 ? "none" : "",
            }}
            className={
              dirty && isValid
                ? "btn btn-primary mr-2 medium-btn"
                : "btn btn-primary mr-2 medium-btn disabled-btn"
            }
          >
            <i className="mdi mdi-chevron-left" /> Previous
          </button>
          <button
            type="button"
            style={{
              display: subTabSecond === subTabs.length ? "none" : "",
            }}
            onClick={() => handleNext()}
            className={
              dirty && isValid
                ? "btn btn-primary mr-2 medium-btn"
                : "btn btn-primary mr-2 medium-btn disabled-btn"
            }
          >
            Next <i className="mdi mdi-chevron-right" />
          </button>
        </div>
      </CardBody>
    </Card>
  );
};

export default BasicInfo;