import { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Illness from "../../components/patientComponents/createPatientForm/Illness";
import FamilyHistory from "../../components/patientComponents/createPatientForm/FamilyHistory";
import BasicInfo from "../../components/patientComponents/createPatientForm/BasicInfo";
import { postPatient } from "../../redux/actions/patientAction";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import { IPatientParams } from "../../redux/types/patientTypes";
import { removeEmptyFieldsFromPatientDiseaseCategory } from "../../helpers/helperFunctions";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import PatientHeader from "../../components/Header/PatientHeader";
 
export interface IDiseaseOptions {
  id: string;
  name: string;
}
 
const CreatePatient = ({toggle}:any) => {
  const [subTabSecond, setsubTabSecond] = useState(1);
  const navigate = useNavigate();
  const { user_info } = useSelector((state: any) => state.user);
  const subTabs = ["Basic info", "Illnesses", "History"/* , "Assessment" , "Diagnosis"*/];
  const handlePrevious = () =>
    setsubTabSecond((prevTab: number) => (prevTab > 0 ? prevTab - 1 : 0));
  const handleNext = () =>
    setsubTabSecond((prevTab: number) =>
      prevTab < (subTabs?.length || 0) ? prevTab + 1 : prevTab
    );
   
  const subTabSecondtoggle = (tab: number) => {
    if (subTabSecond !== tab) setsubTabSecond(tab);
  };
  const getHospitalPropertyId = () => {
    let id = "";
    if (user_info?.is_super_admin === false) {
      id = user_info?.hospital_property?.id;
    }
    return id;
  }
 
  const getHospitalId = () => {
    let id = "";
    if (user_info?.is_super_admin === false) {
      id = user_info?.hospital_property?.hospital?.id;
    }
    return id;
  }
 
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Please enter valid First Name').required("First Name is required"),
    middle_name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid Middle Name').optional(),
    last_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Please enter valid Last Name').required("Last Name is required"),
    blood_group: Yup.string().matches(/^(A|B|AB|O)[+-]?$/, 'Invalid blood group').optional(),
    birth_date: Yup.string().optional(),
    weight: Yup.number().min(0, 'Weight must be a positive number').max(250, 'Weight must be less than or equal to 250 kg').optional(),
    height: Yup.number().min(0, 'Height must be a positive number').max(274, 'Height must be less than or equal to 274 cm').optional(),
    age: Yup.string().optional(),
    email: Yup.string()
      .email('Invalid email')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Please enter valid email')
      .optional(),
    phone_number: Yup.string().matches(/^[56789][0-9]{9}$/, 'Please enter valid Phone Number (10 digits)').optional(),
    // aadhar_card_no: Yup.string().required("Aadhar Card No is required"),
    aadhar_card_no: Yup.string().matches(/^\d{12}$/, 'Please enter valid Aadhar card number (12 digits)').optional(),
    ayushman_card_no: Yup.string().matches(/^[0-9]{12,16}$/, 'Please enter valid Ayushman card (12 to 16 digits)').optional(),
    sex: Yup.string().optional(),
    maritial_status: Yup.string().optional(),
    address: Yup.string().optional(),
    pincode: Yup.string().matches(/^\d{6}$/, 'Please enter valid Pincode (6 digits)').optional(),
    emergency_contact_name: Yup.string().matches(/^[A-Za-z ]+$/, 'Please enter valid Emergency contact name').optional(),
    emergency_contact_relationship: Yup.string().optional(),
    emergency_contact_no: Yup.string().matches(/^[56789][0-9]{9}$/, 'Please enter valid Emergency contact number (10 digits)').optional(),
    country_id: Yup.string().nullable().optional(),
    state_id: Yup.string().nullable().optional(),
    city_id: Yup.string().nullable().optional(),
    childhood_illness: Yup.array().optional(),
    behavioural_illness: Yup.array().optional(),
    injury: Yup.array().optional(),
    injury_name: Yup.string().optional(),
    other_conditions: Yup.array().optional(),
    medical: Yup.array().optional(),
    family_history: Yup.array().optional(),
    family_blood_diseases: Yup.array().optional(),
    medication: Yup.array().optional(),
    drug_allergies: Yup.array().optional(),
    mobility: Yup.string().optional(),
    mobility_comment: Yup.string().optional(),
    stairs_comment: Yup.string().optional(),
    sleep_comment: Yup.string().optional(),
    pain_comment: Yup.string().optional(),
    pain: Yup.string().optional(),
    stairs: Yup.string().optional(),
    sleep: Yup.string().optional(),
    hospital_id: Yup.string().required("Hospital is required"),
    hospital_property_id: Yup.string().required("Hospital Property is required"),
  });
 
  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    blood_group: "",
    birth_date: "",
    weight: "",
    height: "",
    age: '',
    phone_number: "",
    email: "",
    aadhar_card_no: "",
    ayushman_card_no: "",
    sex: "",
    maritial_status: "",
    address: "",
    country_id: null,
    state_id: null,
    city_id: null,
    pincode: "",
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    emergency_contact_no: "",
    childhood_illness: [
      { id: uuidv4(), disease: { id: "", name: "" }, details: "" },
    ],
    other_conditions: [
      { id: uuidv4(), disease: { id: "", name: "" }, details: "" },
    ],
    injury: [{ id: uuidv4(), disease: { id: "", name: "" }, details: "" }],
    injury_name: "",
    behavioural_illness: [
      { id: uuidv4(), disease: { id: "", name: "" }, details: "" },
    ],
    medical: [{ id: uuidv4(), name: "", details: "" }],
    surgical: [{ id: uuidv4(), name: "", details: "" }],
    family_blood_diseases: [
      { id: uuidv4(), disease: { id: "", name: "" }, details: "" },
    ],
    family_history: [{ id: uuidv4(), relation: "", disease: "" }],
    medication: [{ id: uuidv4(), name: "", details: "", strength: "", how_often: "" }],
    drug_allergies: [{ id: uuidv4(), name: "", reaction: "" }],
    mobility: "",
    mobility_comment: "",
    stairs: "",
    stairs_comment: "",
    sleep: "",
    sleep_comment: "",
    pain: "",
    pain_comment: "",
    confidential_questions: [
      {
        id: uuidv4(),
        substances: "",
        quantity: "",
        frequency: "",
        how_long: "",
      },
    ],
    hospital_id: getHospitalId(),
    hospital_property_id: getHospitalPropertyId()
  };
 
  const submitForm = async (values: IPatientParams, resetForm: any) => {
    try {
      const response = await postPatient({
        ...values,
        // hospital_property_id: user_info?.is_super_admin
        //   ? user_info?.hospital_property?.id
        //   : user_info?.hospital_property?.id,
      });
      if (response?.statusCode === 200) {
        SuccessToast(response?.message);
        toggle("1");
        navigate('/patients');
        resetForm();
      } else {
        if (response?.errors?.length > 0) {
          ErrorToast(response?.errors[0]?.msg);
        }
      }
    } catch (error) {
      ErrorToast(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };
 
  return (
    <div>
      <div className="row">
        <div className="col-12 col page-title-box common-top-con">
          {/* <div className="page-title-box common-top-fix-title d-sm-flex align-items-center justify-content-between"> */}
          {/* <PatientHeader /> */}
            {/* <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-sm-0 font-size-14">
                <li className="breadcrumb-item active" aria-current="page">
                  Create Patients
                </li>
              </ol>
            </nav> */}
            <div className="page-title-right d-flex justify-content-center">
              <Nav tabs className="nav-tabs-custom list-view-tab nav-justified">
                {subTabs?.map((subTabName, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={subTabSecond === index + 1 ? "active" : ""}
                      onClick={() => subTabSecondtoggle(index + 1)}
                    >
                      <span className="d-none d-sm-block">{subTabName}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>
          {/* </div> */}
        </div>
        <div className="col-12 col">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              const filteredBehaviouralIllness =
                removeEmptyFieldsFromPatientDiseaseCategory(
                  values?.behavioural_illness
                );
              const filteredFamilyBloodDisease =
                removeEmptyFieldsFromPatientDiseaseCategory(
                  values?.family_blood_diseases
                );
              const filteredChildhoodIllness =
                removeEmptyFieldsFromPatientDiseaseCategory(
                  values?.childhood_illness
                );
              const filteredOtherConditions =
                removeEmptyFieldsFromPatientDiseaseCategory(
                  values?.other_conditions
                );
              const filteredInjury =
                removeEmptyFieldsFromPatientDiseaseCategory(values?.injury);
              const filteredFamilyHistory = values.family_history.filter(
                (item) =>
                  item.relation.trim() !== "" ||
                  item.disease.trim() !== ""
              );
              const filteredConfidentailQuestions =
                values.confidential_questions.filter(
                  (item) =>
                    item.frequency.trim() !== "" ||
                    item.quantity.trim() !== "" ||
                    item.how_long.trim() !== "" ||
                    item.substances.trim() !== ""
                );
              const filteredMedication = values?.medication?.filter(
                (item) =>
                  item.name.trim() !== "" ||
                  item.details.trim() !== "" ||
                  item.strength.trim() !== "" ||
                  item.how_often.trim() !== ""
              );
              const filteredDrugAllergies = values?.drug_allergies?.filter(
                (item) => item.name.trim() !== "" || item.reaction.trim() !== ""
              );
              const filteredHospitalAdmissions =
                values?.medical.filter(
                  (item) => item?.name !== "" || item?.details !== ""
                );
              const filteredSurgical = values?.surgical.filter(
                (item) => item.name.trim() !== "" || item.details.trim() !== ""
              );
              const filteredMedical = values?.medical.filter(
                (item) => item.name.trim() !== "" || item.details.trim() !== ""
              );
              const updatedValues = {
                ...values,
                surgical: filteredSurgical,
                family_blood_diseases: filteredFamilyBloodDisease,
                other_conditions: filteredOtherConditions,
                injury: filteredInjury,
                medical: filteredMedical,
                confidential_questions: filteredConfidentailQuestions,
                childhood_illness: filteredChildhoodIllness,
                family_history: filteredFamilyHistory,
                medication: filteredMedication,
                drug_allergies: filteredDrugAllergies,
                behavioural_illness: filteredBehaviouralIllness,
              };
              submitForm(updatedValues, resetForm);
            }}
          >
            {({
              isValid,
              isSubmitting,
              handleSubmit,
              values,
              handleChange,
              errors,
              touched,
              handleBlur,
              dirty,
              setFieldValue,
            }) => (
              <>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (errors) {
                      const firstError = Object.values(errors)[0];
 
                      if (Array.isArray(firstError)) {
                        const errorMessage = firstError.join(", ");
                        ErrorToast(errorMessage);
                      } else ErrorToast(firstError);
                    }
                    handleSubmit();
                  }}
                >
                  <TabContent activeTab={subTabSecond} className="text-muted">
                    <TabPane tabId={1}>
                      <BasicInfo
                        handlePrevious={handlePrevious}
                        subTabSecond={subTabSecond}
                        dirty={dirty}
                        isValid={isValid}
                        subTabs={subTabs}
                        handleNext={handleNext}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <Illness
                        handlePrevious={handlePrevious}
                        subTabSecond={subTabSecond}
                        dirty={dirty}
                        isValid={isValid}
                        subTabs={subTabs}
                        handleNext={handleNext}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </TabPane>
                    <TabPane tabId={3}>
                      <FamilyHistory
                        handlePrevious={handlePrevious}
                        subTabSecond={subTabSecond}
                        dirty={dirty}
                        isValid={isValid}
                        subTabs={subTabs}
                        handleNext={handleNext}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </TabPane>
                    {/* <TabPane tabId={4}>
                      <Assessment
                        handlePrevious={handlePrevious}
                        subTabSecond={subTabSecond}
                        dirty={dirty}
                        isValid={isValid}
                        subTabs={subTabs}
                        handleNext={handleNext}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </TabPane> */}
                    {/* <TabPane tabId={4}>
                      <PatientsDiagnosis
                        handlePrevious={handlePrevious}
                        subTabSecond={subTabSecond}
                        dirty={dirty}
                        isValid={isValid}
                        subTabs={subTabs}
                        handleNext={handleNext}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </TabPane> */}
                  </TabContent>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
 
export default CreatePatient;