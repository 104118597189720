import React from 'react';
import { Label } from 'reactstrap';
import CustomMUIDatePicker from '../UI/CustomMUIDatePicker';

interface Props {
  filters: any;
  handleFilterChange: (name: string, value?: string) => void;
  isAllCompleted: boolean;
  handleSearch: () => void;
  handleIncompleteSearch: () => void;
  handleReset: () => void;
  handleResetCompleted: () => void;
}

const CustomDropdownBox: React.FC<Props> = ({
  filters,
  handleFilterChange,
  isAllCompleted,
  handleSearch,
  handleIncompleteSearch,
  handleReset,
  handleResetCompleted,
}) => {
  return (
    <div className="col-12 instruction-search page-title-right d-flex mb-3">
      <div className="mr-2 col-md-3">
        <Label className="form-label mr-5"> Start Date </Label>{" "}
        <CustomMUIDatePicker
          name="start_date"
          type="formik"
          value={filters?.start_date}
          setState={handleFilterChange}
        />
      </div>
      <div className="mr-2 col-md-3">
        <Label className="form-label mr-5"> End Date </Label>{" "}
        <CustomMUIDatePicker
          name="end_date"
          type="formik"
          value={filters?.end_date}
          setState={handleFilterChange}
        />
      </div>
      <div className="mr-2 col-md-3">
        <Label className="form-label mr-5">Sort </Label>
        <div>
          {isAllCompleted ? (
            <select
              className="form-control"
              value={filters.sort_order}
              name="sort_order"
              onChange={(e) => {
                handleFilterChange('sort_order', e.target.value);
              }}
            >
              <option style={{ color: "#000" }} hidden>
                Sort by
              </option>
              <option style={{ color: "#000" }} value="ASC">
                Ascending
              </option>
              <option style={{ color: "#000" }} value="DESC">
                Descending
              </option>
            </select>
          ) : (
            <select
              className="form-control"
              value={filters.sort_order}
              name="sort_order"
              onChange={(e) => {
                handleFilterChange('sort_order', e.target.value);
              }}
            >
              <option style={{ color: "#000" }} hidden>
                Sort by
              </option>
              <option style={{ color: "#000" }} value="ASC">
                Ascending
              </option>
              <option style={{ color: "#000" }} value="DESC">
                Descending
              </option>
            </select>
          )}
        </div>
      </div>
      <div className="mr-2 col-md-3">
        <Label className="form-label mr-5" />{" "}
        <div>
          {isAllCompleted ? (
            <button
              type="button"
              onClick={handleIncompleteSearch}
              className="btn btn-info medium-btn mt-4 mr-5 ml-1"
            >
              Search
            </button>
          ) : (
            <button
              type="button"
              onClick={handleSearch}
              className="btn btn-info medium-btn mt-4 mr-5 ml-1"
            >
              Search
            </button>
          )}
        </div>
        <Label className="form-label mr-5" />{" "}
        <button
          type="button"
          onClick={isAllCompleted ? handleReset : handleResetCompleted}
          className="btn btn-secondary mt-4 mr-5 ml-1"
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default CustomDropdownBox;