import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  FC,
} from "react";
import CustomMUIDatePicker from "../../../components/UI/CustomMUIDatePicker";
import { Card, CardBody, Input, Label, TabContent, TabPane } from "reactstrap";
import UserRolesForm from "./UserRolesForm";
import { ErrorToast, SuccessToast } from "../../../utils/toastNotifications";
import { deleteRole, getRolesList } from "../../../redux/actions/roleAction";
import { findTextInString } from "../../../helpers/helperFunctions";
import { ERROR_MESSAGES } from "../../../helpers/constants";
import norole from "../../../assets/images/nodata-found/user-role.png";
import DeleteConfirmationModal from "../../../components/DeletePopupComponent/DeletePopup";
import SvgFilesIcon from "../../../components/SvgFiles/SvgFiles";
const UserRolesListing: FC = () => {
  const [mode, setmode] = useState("add");
  const [user_roless, setuser_roless] = useState([]);
  const [loading, setloading] = useState(false);
  const [show_form, set_show_form] = useState(false);
  const [update_user_roles, set_update_user_roles] = useState<any>();
  const [search, set_search] = useState("");
  const [filters, set_filters] = useState({
    start_date: "",
    end_date: "",
    sort_order: " ",
  });
  const [filterOpen, setfilterOpen] = useState<boolean>(false);
  const [subTab, setsubTab] = useState("1");
 
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userRoleToDelete, setUserRoleToDelete] = useState<any>(null);
 
 
  const handleFilterChange = (name: string, value?: string) => {
    set_filters((prev_filters) => ({ ...prev_filters, [name]: value }));
  };
 
 
  const fetch_user_roless = async () => {
    setloading(true);
    let response = await getRolesList({});
    if (response?.statusCode === 200) {
      setuser_roless(response?.body?.data);
      setloading(false);
    } else {
      setloading(false);
      setuser_roless([]);
    }
  };
 
  const handle_apply_filters = async () => {
    setloading(true);
    const { start_date, end_date, sort_order } = filters;
 
    if (
      start_date.trim() === "" &&
      end_date.trim() === "" &&
      sort_order.trim() === ""
    ) {
      ErrorToast(ERROR_MESSAGES.noFiltersSelected);
      setloading(false);
      return;
    }
 
    if ((start_date && !end_date) || (!start_date && end_date)) {
      ErrorToast(ERROR_MESSAGES.missingDates);
      setloading(false);
      return;
    }
 
    if (start_date && end_date) {
      if (!start_date.trim() || !end_date.trim()) {
        ErrorToast(ERROR_MESSAGES.emptyDates);
        setloading(false);
        return;
      }
 
      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);
 
      if (endDateObj < startDateObj) {
        ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
        setloading(false);
        return;
      }
    }
 
    const request = await getRolesList({ start_date, end_date, sort_order });
    if (request?.statusCode === 200) {
      setuser_roless(request?.body?.data);
      setloading(false);
    } else {
      setuser_roless([]);
      setloading(false);
    }
  };
 
  const handle_reset_filters = () => {
    set_filters({ start_date: "", end_date: "", sort_order: " " });
    fetch_user_roless();
    set_update_user_roles(null);
    setfilterOpen(false);
  };
 
  useEffect(() => {
    fetch_user_roless();
  }, []);
 
  const handle_edit_user_roles = useCallback(
    (user_roles: any) => {
      set_update_user_roles(user_roles);
      set_show_form(true);
      setmode("edit");
    },
 
    []
  );
 
  // const handle_delete_user_roles = async (user_roles: any) => {
  //   const response = await deleteRole(user_roles?.id);
  //   if (response?.statusCode === 200) {
  //     SuccessToast("Delete Successfull");
  //     fetch_user_roless();
  //     set_update_user_roles(null);
  //   } else {
  //     ErrorToast("Server Error, Try Again");
  //   }
  // };
 
  const handleDeleteUserRole = async () => {
    if (!userRoleToDelete) {
      // If userRoleToDelete is null, return early or handle the error accordingly
      console.error("User to delete is null");
      return;
    }
 
    const response = await deleteRole(userRoleToDelete?.id);
    if (response?.statusCode === 200) {
      SuccessToast("Delete Successfull");
      fetch_user_roless();
      set_update_user_roles(null);
    } else {
      ErrorToast("Server Error, Try Again");
    }
    setDeleteModalOpen(false);
  };
 
  const filtered_user_roless = useMemo(
    () => user_roless.filter(({ role }) => findTextInString(role, search)),
    [search, user_roless]
  );
 
  const handle_filter_open_close = () => {
    set_show_form(false);
    setmode("add");
    set_update_user_roles(null);
    setfilterOpen((filterOpen) => !filterOpen);
  };
 
  const handle_user_role_form = () => {
    setfilterOpen(false);
    setmode("add");
    set_update_user_roles(null);
    set_show_form((show_form) => !show_form);
  };
 
  return (<>
    {loading && (
      <div className="loader-box">
        <span className="loader"></span>
      </div>
    )}
    <div className="col-12 col">
      <div className="col-12 col">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-sm-0 font-size-14">
              <li className="breadcrumb-item active" aria-current="page">
                {/* User Roles */}
              </li>
            </ol>
          </nav>
          <div className="page-title-right d-flex">
            <button
              type="button"
              className="btn btn-primary medium-btn"
              onClick={() => handle_user_role_form()}
            >
              Add
            </button>
            <div className="mr-2 patient-search-box">
                <div className="search-box-wrapper">
                  <SvgFilesIcon iconname={"search"} />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Role"
                    onChange={(e: any) => set_search(e.target.value)}
                  />
                </div>
              </div>
            <div className="mr-2">
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => handle_filter_open_close()}
              >
                <i className="mdi mdi-filter-outline"></i> Filter
                <i className="mdi mdi-chevron-down" />
              </button>
 
              {filterOpen && (
                <div className="filter-box">
                  <button
                    className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                    onClick={() => handle_filter_open_close()}
                  ><i className="mdi mdi-close" />
                  </button>
                  <div className="row">
                    <div className="col-6 mb-3">
                      <Label className="form-label">From Date</Label>
                      <CustomMUIDatePicker
                        name="start_date"
                        type="formik"
                        value={filters?.start_date}
                        setState={handleFilterChange}
                      />
                    </div>
                    <div className="col-6 mb-3">
                      <Label className="form-label">To Date</Label>
                      <CustomMUIDatePicker
                        name="end_date"
                        type="formik"
                        value={filters?.end_date}
                        setState={handleFilterChange}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <Label className="form-label">Filter By Order</Label>
                      <select
                        onChange={(e) => handleFilterChange("sort_order", e.target.value)}
                        className="form-control"
                        name="sort_order"
                        value={filters.sort_order}
                      >
                        <option disabled value=" ">
                          Select Order
                        </option>
                        <option style={{ color: "black" }} value="ASC">
                          Ascending
                        </option>
                        <option style={{ color: "black" }} value="DESC">
                          Decending
                        </option>
                      </select>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        onClick={() => handle_apply_filters()}
                        className="btn btn-info medium-btn mr-2"
                      >
                        Apply
                      </button>
                      <button
                        onClick={() => handle_reset_filters()}
                        type="button"
                        className="btn btn-secondary medium-btn"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <ul className="nav-tabs-custom list-view-tab nav-justified nav nav-tabs">
              <li className="nav-item" onClick={() => setsubTab("1")}>
                <a className={`nav-link ${subTab === "1" ? "active" : ""}`}>
                  <span className="d-none d-sm-block">
                    <i className="mdi mdi-grid"></i>
                  </span>
                </a>
              </li>
              <li className="nav-item" onClick={() => setsubTab("2")}>
                <a className={`nav-link ${subTab === "2" ? "active" : ""}`}>
                  <span className="d-none d-sm-block">
                    <i className="mdi mdi-format-list-bulleted" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <UserRolesForm
        setIsFormOpen={set_show_form}
        isFormOpen={show_form}
        user_roles={update_user_roles}
        mode={mode}
        fetch_user_roless={fetch_user_roless}
      />
      <TabContent activeTab={subTab} className="text-muted user-role-list-content">
 
        <TabPane tabId="1">
          <div className="user-card-box user-roles-box">
            {filtered_user_roless?.length > 0 ? (
              filtered_user_roless?.map((user_roles: any) => (
                <div className="user-box" key={user_roles?.id}>
                  <div>
                    <div className="user-title-box">
                      <div className="icon-box">
                        <h3>{user_roles?.role?.split("")[0]}</h3>
                        <h4>{user_roles?.role}</h4>
                      </div>
                      <div className="user-setting-box">
                        <div className="edit-box" onClick={() => handle_edit_user_roles(user_roles)}>
                          <SvgFilesIcon iconname={"useredit"} />
                        </div>
                        <div className="delete-box" onClick={() => {
                          setUserRoleToDelete(user_roles); // Set the user to be deleted
                          setDeleteModalOpen(true); // Open the delete confirmation modal
                        }}>
                          <SvgFilesIcon iconname={"delete"} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data-found">
                <img src={norole} width={80}></img>
                <h4 className="mt-2">No User Roles Found</h4>
 
              </div>
            )}
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="row user-list-view-section">
            <div className="col-12">
              {filtered_user_roless?.length > 0 ? (
                <Card>
                  <CardBody className="">
                    <div className="table-responsive">
                      <table className="table table-bordered table-nowrap mb-0 table-list">
                        <thead className="table-light">
                          <tr>
                            <th>User Role</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filtered_user_roless?.map((user_roles: any) => (
                            <tr key={user_roles?.id}>
                              <td>
                                <div className="icon-box">
                                  <h3>{user_roles?.role?.split("")[0]}</h3>
                                  <h4>{user_roles?.role}</h4>
                                </div>
                              </td>
                              <td>
                                <div className="user-setting-box">
                                  <div className="edit-box" onClick={() => handle_edit_user_roles(user_roles)}>
                                    <SvgFilesIcon iconname={"useredit"} />
                                  </div>
                                      <div className="delete-box" onClick={() => {
                                        setUserRoleToDelete(user_roles); // Set the user to be deleted
                                        setDeleteModalOpen(true); // Open the delete confirmation modal
                                      }}>
                                        <SvgFilesIcon iconname={"delete"} />
                                      </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    minHeight: 300,
                    marginTop: 250,
                  }}
                >
                  <img src={norole} width={80}></img>
                  <h4 className="mt-2">No User Roles Found</h4>
                </div>
              )}
            </div>
          </div>
        </TabPane>
      </TabContent>
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(!deleteModalOpen)}
        pageName={userRoleToDelete ? userRoleToDelete?.role : ""} // Pass the user name to the modal
        onConfirm={handleDeleteUserRole}
      />
    </div>
  </>
  );
};
 
export default UserRolesListing;
 