import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CardTitle } from "reactstrap";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
} from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { ErrorToast, SuccessToast } from "../../../../utils/toastNotifications";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { LinearProgress, MenuItem, Select } from "@mui/material";
import NoRowsOverlay from "../../../../components/DataGrid/NoRowsOverlay";
import { grid_styles } from "../grid_styles";
import { IDiseaseOptions } from "../../CreatePatient";
import { updatePatient } from "../../../../redux/actions/patientAction";
import { getPatientBehaviouralConditionssList } from "../../../../redux/actions/patientBehaviouralConditionAction";
import DeleteConfirmationModal from "../../../../components/DeletePopupComponent/DeletePopup";

const PatientBehaviouralIllness: React.FC<any> = ({
  patient,
  fetchPatientData,
}) => {
  const initialRows = useMemo(
    () => [{ id: "1", disease: { name: "", id: "" }, details: "" }],
    []
  );

  type Row = (typeof initialRows)[number];

  const [page_number] = useState(1);
  const [per_page] = useState(8);
  const [rows, setRows] = useState<any[]>(initialRows);
  const [rowId, setrowId] = useState(null);
  const processRowUpdate = (newRow: GridRowModel) => newRow;
  const onCellEditCommit = (params: any) => setrowId(params?.id);
  const [behavioural_diseases, setbehavioural_diseases] = useState<IDiseaseOptions[]>();
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const addRow = () => {
    if (rows?.some((row) => row?.id === initialRows[0]?.id)) {
      ErrorToast("Please save last added row.");
      return rows;
    }
    setRows((prevRows) => [
      { id: "1", disease: { name: " ", id: "" }, details: "" },
      ...prevRows,
    ]);
  };

  const handleUpdatePatientBehaviouralIllness = useCallback(
    async ({
      id,
      disease,
      details,
    }: {
      id: string;
      disease: { id: string; name: string };
      details: string;
    }) => {
      if (disease?.name?.trim() === "")
        return ErrorToast("Disease name is required field!");
      if (details.trim() === "")
        return ErrorToast("details is required field!");
      if (id === initialRows[0]?.id) {
        try {
          await updatePatient({
            id: patient?.id,
            behavioural_illness: rows
              .filter((row) => row?.id !== "1")
              .concat({ id: uuidv4(), disease, details }),
          });
          fetchPatientData();
          SuccessToast("Behavioural Illness Added Successfully");
          setrowId(null);
        } catch (error) {
          SuccessToast("Error Saving Data, Try Again");
        }
      } else {
        let item_to_update = rows?.findIndex((obj: any) => {
          return obj.id === id;
        });

        rows[item_to_update].id = uuidv4();
        rows[item_to_update].disease = disease;
        rows[item_to_update].details = details;
        try {
          await updatePatient({
            id: patient?.id,
            behavioural_illness: rows?.filter((row) => row?.id !== "1"),
          });
          SuccessToast("Behavioural Illness Updated Successfully");
          setrowId(null);
        } catch (error) {
          SuccessToast("Error Saving Data, Try Again");
        }
      }
    },
    [rows, patient?.id, initialRows]
  );

  const handleDeletePatientBehaviouralIllness = useCallback(
    async ({ id }: { id: string }) => {
      try {
        const updatedRows = rows.filter((row) => row.id !== id);
        await updatePatient({
          id: patient?.id,
          behavioural_illness: updatedRows,
        });
        fetchPatientData();
        SuccessToast("Behavioural Illness Deleted Successfully");
      } catch (error) {
        SuccessToast("Error Saving Data, Try Again");
      }
    },
    [rows, patient?.id, fetchPatientData]
  );

  const fetchPatientBehaviouralIllnessList = useCallback(async () => {
    const { body } = await getPatientBehaviouralConditionssList({});
    const updatedBehaviouralIllnessList: IDiseaseOptions[] = body?.data?.map(
      (e: any) => ({ name: e?.name, id: e?.id })
    );
    setbehavioural_diseases(updatedBehaviouralIllnessList);
  }, []);

  useEffect(() => {
    fetchPatientBehaviouralIllnessList();
  }, [fetchPatientBehaviouralIllnessList]);

  const handleDeleteConfirmation = useCallback(() => {
    if (deleteRowId) {
      handleDeletePatientBehaviouralIllness({ id: deleteRowId });
      setDeleteRowId(null);
      setIsDeleteModalOpen(false);
    }
  }, [deleteRowId, handleDeletePatientBehaviouralIllness]);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  const columns: any = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: "disease",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: false,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => {
          const handleChange = (selectedBehaviouralDiseaseId: string) => {
            setrowId(params.id);
            const selectedBehaviouralDisease = behavioural_diseases?.find(
              ({ id }) => id === selectedBehaviouralDiseaseId
            );
            if (!selectedBehaviouralDisease) return;
            const rowIndex = rows?.findIndex((row) => row?.id === params?.id);
            if (rowIndex === -1) return;

            const isParamsIdOne = params.id === 1;
            const row = params.row || {};

            const updatedRow = {
              ...rows[rowIndex],
              disease: selectedBehaviouralDisease,
              details: rows[rowIndex]?.details?.trim() !== "" && isParamsIdOne ? rows[rowIndex]?.details : row?.details,
            };
            const updatedRows = [
              ...rows.slice(0, rowIndex),
              updatedRow,
              ...rows.slice(rowIndex + 1),
            ];
            setRows(updatedRows);
          };
          return (
            <div style={{ width: "100%" }}>
              <Select
                MenuProps={{ autoFocus: false }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0 !important",
                  },
                  "& :focus": {
                    outline: "none !important",
                    border: "0 !important",
                    boxShadow: "none !important",
                  },
                  width: "100%",
                  color: "#a6b0cf",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
                renderValue={() => params.value?.name.trim() === "" ? "Select Behavioural Illness" : params.value?.name}
                value={params.value?.id}
                onChange={(event) => handleChange(event.target.value)}
              >
                <MenuItem value=" " disabled>
                  <em>Select Behavioural Diseases Type</em>
                </MenuItem>
                {behavioural_diseases && behavioural_diseases?.length > 0 ?
                  behavioural_diseases?.map(({ name, id }: IDiseaseOptions) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  )) :
                  (
                    <MenuItem disabled>
                      No Behavioural Diseases Found
                    </MenuItem>
                  )}
              </Select>
            </div>
          );
        },
        valueOptions: behavioural_diseases,
      },
      {
        field: "details",
        type: "TextField",
        flex: 1,
        filterable: true,
        sortable: true,
        editable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "actions",
        type: "actions",
        width: 150,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            color={"primary"}
            disabled={params?.id !== rowId}
            onClick={() =>
              handleUpdatePatientBehaviouralIllness({
                id: params.row.id.toString(),
                disease: {
                  id: params.row.disease?.id,
                  name: params?.row?.disease?.name,
                },
                details: params.row.details,
              })
            }
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            disabled={params?.id === initialRows[0]?.id}
            onClick={() => {
              setDeleteRowId(params.row.id.toString());
              toggleDeleteModal()
            }}
          />,
        ],
      },
    ],
    [
      behavioural_diseases,
      toggleDeleteModal,
      handleUpdatePatientBehaviouralIllness,
      initialRows,
      rowId,
      rows,
    ]
  );

  useEffect(() => {
    if (!patient?.behavioural_illness) setRows([]);
    else setRows(patient?.behavioural_illness);
  }, [page_number, per_page, patient?.behavioural_illness]);

  const handleRowUpdateError = (error: any) => { };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        pageName="behavioural illness"
        onConfirm={handleDeleteConfirmation}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <CardTitle className="h4 hospital-card-title text-primary">
            Behavioural Illness
          </CardTitle>
        </div>
        <div>
          <button
            className="btn btn-primary mb-3"
            type="button"
            onClick={() => addRow()}
          >
            Add Row
          </button>
        </div>
      </div>
      <div style={{ height: "auto", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="cell"
          rowHeight={35}
          columnHeaderHeight={35}
          autoHeight={true}
          disableRowSelectionOnClick={true}
          pagination={true}
          processRowUpdate={processRowUpdate}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5, page: 0 },
            },
          }}
          slots={{
            noRowsOverlay: NoRowsOverlay,
            loadingOverlay: LinearProgress,
          }}
          paginationMode="client"
          sx={grid_styles}
          onCellEditStart={onCellEditCommit}
          onProcessRowUpdateError={handleRowUpdateError}
        />
      </div>
    </>
  );
};

export default PatientBehaviouralIllness;
