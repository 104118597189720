import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PersonalDetails from "../patientDetailCards/patientAdmissionsDetails/PersonalDetails";
import SystemicExamination from "../patientDetailCards/patientAdmissionsDetails/SystemicExamination";
import Odp from "../patientDetailCards/patientAdmissionsDetails/Odp";
import Habits from "../patientDetailCards/patientAdmissionsDetails/Habits";
import ProvisionalDiagnosis from "../patientDetailCards/patientAdmissionsDetails/ProvisionalDiagnosis";
import GeneralExamination from "../patientDetailCards/patientAdmissionsDetails/GeneralExamination";
import PatientAdmissionHRInput from "../patientDetailCards/patientAdmissionsDetails/patientAdmissionHourlyReport/PatientAdmissionHRInput";
import PatientAdmissionHROutput from "../patientDetailCards/patientAdmissionsDetails/patientAdmissionHourlyReport/PatientAdmissionHROutput";
import PatientAdmissionHRPatientData from "../patientDetailCards/patientAdmissionsDetails/patientAdmissionHourlyReport/PatientAdmissionHRPatientData";
import PatientAdmissionHRVentilator from "../patientDetailCards/patientAdmissionsDetails/patientAdmissionHourlyReport/PatientAdmissionHRVentilator";
import PatientAdmissionHRVitals from "../patientDetailCards/patientAdmissionsDetails/patientAdmissionHourlyReport/PatientAdmissionVitals";
import Prescription from "../patientDetailCards/patientAdmissionsDetails/Prescription";
import ClinicalNotes from "../patientDetailCards/patientAdmissionsDetails/ClinicalNotes";
import LineTubes from "../patientDetailCards/patientAdmissionsDetails/LineTubes";
import Observation from "../patientDetailCards/patientAdmissionsDetails/Observation";
import Dietry from "../patientDetailCards/patientAdmissionsDetails/Dietry";
import Investigation from "../patientDetailCards/patientAdmissionsDetails/Investigation";
import Instructions from "./patientAdmissionsDetails/Instructions";
import PatientAdmissionSugar from "./patientAdmissionsDetails/patientAdmissionHourlyReport/PatientAdmissionSugar";
import PatientAdmissionInfusionPump from "./patientAdmissionsDetails/patientAdmissionHourlyReport/PatientAdmissionInfusionPump";
import CustomMUIDatePicker from "../../../components/UI/CustomMUIDatePicker";
import PatientDocuments from "./patientAdmissionsDetails/patientDocuments";
 
const PatientDetailAdmission: React.FC<any> = ({
  selected_admission,
  setselected_admission,
  patient
}) => {
  const [selected_date, setSelectedDate] = useState<string>(new Date().toISOString());
  const [subTabThree, setsubTabThree] = useState("1");
  const subTabThreetoggle = (tab: string) => {
    if (subTabThree !== tab) setsubTabThree(tab);
  };
 
  const [toggle_sidebar, settoggle_sidebar] = useState(true);
  const [activeTab, setactiveTab] = useState("2");
  const [loader, setLoader] = useState(false);
  // const toggle = (tab: any) => {
  //   if (activeTab !== tab) {
  //     setactiveTab(tab);
  //   }
  // };
 
  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setLoader(true); // Start loading
      // Simulate loading time
      setTimeout(() => {
      setactiveTab(tab);
        setLoader(false); // Stop loading after delay
      }, 200); // Adjust delay as needed
    }
  };
 
  const handleAdmissionDateChange = (selectedDate: string) => {
    const dateObject = new Date(selectedDate);
    const formattedDate = dateObject.toISOString();
    setSelectedDate(formattedDate);
  }
 
  return (
<>
{loader && (
        <div className="loader-box">
          <span className="loader"></span>
        </div>
      )}
    <Card>
      <CardBody
        className={`${toggle_sidebar ? "admission-card patient-admission-card" : "admission-card close-left"
          }`}
        style={{ transition: "all .5s ease-in-out" }}
      >
        <div className="row">
          <div className="left-box patient-left-card">
            {toggle_sidebar && (
              <div
                className="tab-btn open-btn"
                onClick={() => settoggle_sidebar(false)}
              >
                <i className="bx bx-chevrons-left"></i>
              </div>
            )}
            {!toggle_sidebar && (
              <div
                className="tab-btn close-btn"
                onClick={() => settoggle_sidebar(true)}
              >
                <i className="bx bx-chevrons-right"></i>
              </div>
            )}
            <Nav tabs className=" nav nav-pills">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={subTabThree === "1" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("1");
                  }}
                >
                  <span className="d-none d-sm-block hospital-name">
                    Personal Detail
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "4" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("4");
                  }}
                >
                  <span className="d-none d-sm-block">ODP</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={subTabThree === "2" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("2");
                  }}
                >
                  <span className="d-none d-sm-block hospital-name">
                    General Examination
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "3" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("3");
                  }}
                >
                  <span className="d-none d-sm-block">
                    Systemic Examination
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "9" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("9");
                  }}
                >
                  <span className="d-none d-sm-block">Clinical Notes</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "13" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("13");
                  }}
                >
                  <span className="d-none d-sm-block">Investigation</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "8" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("8");
                  }}
                >
                  <span className="d-none d-sm-block">Prescription</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "6" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("6");
                  }}
                >
                  <span className="d-none d-sm-block">
                    Diagnosis
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "10" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("10");
                  }}
                >
                  <span className="d-none d-sm-block">Lines/Tubes</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "7" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("7");
                  }}
                >
                  <span className="d-none d-sm-block">Hourly Report</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "11" ? "active" : ""}
                  onClick={() => {
                    subTabThreetoggle("11");
                  }}
                >
                  <span className="d-none d-sm-block">Dietary</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "14" ? "active" : ""}
                  onClick={() => subTabThreetoggle("14")}>
                  <span className="d-none d-sm-block">Instructions</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={subTabThree === "15" ? "active" : ""}
                  onClick={() => subTabThreetoggle("15")}>
                  <span className="d-none d-sm-block">Documents</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="right-box patient-right-card">
            <TabContent activeTab={subTabThree} className="text-muted">
              {subTabThree === "1" &&
                <TabPane tabId="1">
                  <h4 className="tab-title">Personal Detail</h4>
                  <PersonalDetails
                    selected_admission={selected_admission}
                    setselected_admission={setselected_admission}
                  />
                </TabPane>
              }
              {subTabThree === "2" &&
                <TabPane tabId="2">
                  <h4 className="tab-title">General Examination</h4>
                  <GeneralExamination
                    selected_admission={selected_admission}
                    setselected_admission={setselected_admission}
                  />
                </TabPane>
              }
              {subTabThree === "3" &&
                <TabPane tabId="3">
                  <h4 className="tab-title">Systemic Examination</h4>
                  <SystemicExamination
                    selected_admission={selected_admission}
                    setselected_admission={setselected_admission}
                  />
                </TabPane>
              }
 
              {subTabThree === "4" &&
                <TabPane tabId="4">
                  <h4 className="tab-title">ODP</h4>
                  <Odp
                    selected_admission={selected_admission}
                    setselected_admission={setselected_admission}
                  />
                </TabPane>
              }
              {subTabThree === "5" &&
                <TabPane tabId="5">
                  <h4 className="tab-title">Habits</h4>
                  <Habits
                    selected_admission={selected_admission}
                    setselected_admission={setselected_admission}
                  />
                </TabPane>
              }
              {subTabThree === "6" &&
                <TabPane tabId="6">
                  <h4 className="tab-title">Diagnosis</h4>
                  <ProvisionalDiagnosis
                    selected_admission={selected_admission}
                    setselected_admission={setselected_admission}
                  />
                </TabPane>
              }
              {subTabThree === "7" &&
                <TabPane tabId="7">
                  <h4 className="tab-title ">Hourly Report</h4>
                  <div className="fixed-right">
                    <CustomMUIDatePicker
                      name="selected_date"
                      type="simple"
                      setState={handleAdmissionDateChange}
                      maxDate={new Date()}
                      value={selected_date}
                    />
                  </div>
 
                  <div className="row">
                    <div className="col-12">
                      <Nav tabs className="nav-tabs-custom list-view-tab nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "6" ? "active" : ""}
                            onClick={() => {
                              toggle("6");
                            }}
                          >
                            Vitals
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "7" ? "active" : ""}
                            onClick={() => {
                              toggle("7");
                            }}
                          >
                            Sugar
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            Input
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => {
                              toggle("3");
                            }}
                          >
                            Output
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "5" ? "active" : ""}
                            onClick={() => {
                              toggle("5");
                            }}
                          >
                            Ventilator Data
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "4" ? "active" : ""}
                            onClick={() => {
                              toggle("4");
                            }}
                          >
                            Patient Data
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "8" ? "active" : ""}
                            onClick={() => {
                              toggle("8");
                            }}
                          >
                            Infusion Pump
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
 
                  <div className="row hourlyreport-table-size">
                    <TabContent
                      activeTab={activeTab}
                      className="p-3 text-muted"
                    >
                      {activeTab === "2" &&
                        <TabPane tabId="2">
                          <PatientAdmissionHRInput
                            selected_date={selected_date}
                            selected_admission={selected_admission}
                          />
                        </TabPane>
                      }
                      {activeTab === "3" &&
                        <TabPane tabId="3">
                          <PatientAdmissionHROutput
                            selected_date={selected_date}
                            selected_admission={selected_admission}
                          />
                        </TabPane>
                      }
                      {activeTab === "4" &&
                        <TabPane tabId="4">
                          <PatientAdmissionHRPatientData
                            selected_date={selected_date}
                            selected_admission={selected_admission}
                          />
                        </TabPane>
                      }
                      {activeTab === "5" &&
                        <TabPane tabId="5">
                          <PatientAdmissionHRVentilator
                            selected_date={selected_date}
                            selected_admission={selected_admission}
                          />
                        </TabPane>
                      }
                      {activeTab === "6" &&
                        <TabPane tabId="6">
                          <PatientAdmissionHRVitals
                            selected_date={selected_date}
                            selected_admission={selected_admission}
                          />
                        </TabPane>
                      }
                      {activeTab === "7" &&
                        <TabPane tabId="7">
                          <PatientAdmissionSugar
                            selected_date={selected_date}
                            selected_admission={selected_admission}
                          />
                        </TabPane>
                      }
                      {activeTab === "8" &&
                        <TabPane tabId="8">
                          <PatientAdmissionInfusionPump
                            selected_date={selected_date}
                            selected_admission={selected_admission}
                          />
                        </TabPane>
                      }
                    </TabContent>
                  </div>
                </TabPane>
              }
              {subTabThree === "8" &&
                <TabPane tabId="8">
                  <h4 className="tab-title">Prescription</h4>
                  <Prescription selected_admission={selected_admission} />
                </TabPane>
              }
              {subTabThree === "9" &&
                <TabPane tabId="9">
                  <h4 className="tab-title">Clinical Notes</h4>
                  <ClinicalNotes selected_admission={selected_admission} />
                </TabPane>
              }
              {subTabThree === "10" &&
                <TabPane tabId="10">
                  <h4 className="tab-title">Lines/Tubes</h4>
                  <LineTubes selected_admission={selected_admission} />
                </TabPane>
              }
              {subTabThree === "11" &&
                <TabPane tabId="11">
                  <h4 className="tab-title">Dietary</h4>
                  <Dietry selected_admission={selected_admission} />
                </TabPane>
              }
              {subTabThree === "12" &&
                <TabPane tabId="12">
                  <h4 className="tab-title">Observations</h4>
                  <Observation selected_admission={selected_admission} />
                </TabPane>
              }
              {subTabThree === "13" &&
                <TabPane tabId="13">
                  <h4 className="tab-title">Investigation</h4>
                  <Investigation selected_admission={selected_admission} />
                </TabPane>
              }
              {subTabThree === "14" &&
                <TabPane tabId="14">
                  <h4 className="tab-title">Instructions</h4>
                  <Instructions patient={patient} selected_admission={selected_admission} />
                </TabPane>
              }
              {subTabThree === "15" &&
                <TabPane tabId="15">
                  <h4 className="tab-title">Documents</h4>
                  <PatientDocuments patient={patient} selected_admission={selected_admission} />
                </TabPane>
              }
 
            </TabContent>
          </div>
        </div>
      </CardBody>
    </Card>
    </>
 
  );
};
 
export default PatientDetailAdmission;